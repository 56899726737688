import React from "react"
import { graphql } from "gatsby"
import PropTypes from "prop-types"
import {
  CallToAction,
  Hero,
  Kicker,
  Image,
  Section,
  ProductCard,
  Heading,
  SEO,
} from "components"

const DepartmentTemplate = ({
  data: {
    products: { edges: products },
    sanityDepartment: { title, image, slug, description },
    file01,
    file02,
    file03,
  },
}) => {
  console.log(file01)
  return (
    <>
      <SEO title={title} />

      <Hero
        title={title}
        image={image || undefined}
        backgroundColor={slug.current}
        description={
          description
            ? description
            : `Produtos da Bondmann Química para ${title}`
        }
      />

      {"bd-service" === slug.current && (
        <Section background="bg-gray-200">
          <Heading level="3" className="max-w-2xl">
            A Bondmann busca promover no mercado industrial uma cultura calcada
            em três pilares essenciais que buscam trazer a seus clientes o
            máximo resultado em suas operações:
          </Heading>

          <div className="grid md:grid-cols-3 gap-16 mt-24">
            <div className="bg-white border-b-4 border-accent-400 p-8 space-y-8 rounded-sm shadow-soft">
              <Image
                className="border-4 border-accent-500 rounded-full shadow-soft -mt-20 w-24 mx-auto"
                source={file01}
              />
              <Kicker>
                Utilize sempre os EPI’s Recomendados a sua operação
              </Kicker>

              <p>
                Assim, você garante sua segurança e de sua equipe, evitando e
                mitigando acidentes e doenças ocupacionais.
              </p>
            </div>

            <div className="bg-white border-b-4 border-accent-400 p-8 space-y-8 rounded-sm shadow-soft">
              <Image
                className="border-4 border-accent-500 rounded-full shadow-soft -mt-20 w-24 mx-auto"
                source={file03}
              />
              <Kicker>
                Promova um descarte adequados de seus resíduos e efluentes
              </Kicker>

              <p>
                Respeitando a legislação vigente, você não só contribui para a
                melhora de meio ambiente e nosso eco-sistema como evita multas
                ambientais.
              </p>
            </div>

            <div className="bg-white border-b-4 border-accent-400 p-8 space-y-8 rounded-sm shadow-soft">
              <Image
                className="border-4 border-accent-500 rounded-full shadow-soft -mt-20 w-24 mx-auto"
                source={file02}
              />
              <Kicker>
                Implemente as melhores práticas alinhadas a sua operação
              </Kicker>

              <p>
                Reduzindo custos/desperdício e melhorando sua produtividade,
                resultando em maior rentabilidade para seu negócio.
              </p>
            </div>
          </div>
        </Section>
      )}

      <Section>
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-8">
          {products.map(({ node: { title, slug, department, image } }) => {
            return (
              <ProductCard
                key={slug.current}
                title={title}
                to={`/produtos/${slug.current}`}
                department={department}
                image={image}
              />
            )
          })}
        </div>
      </Section>

      <CallToAction />
    </>
  )
}

DepartmentTemplate.propTypes = {
  data: PropTypes.oneOfType([PropTypes.node, PropTypes.object]).isRequired,
}

export default DepartmentTemplate

export const query = graphql`
  query GET_DEPARTMENT($slug: String) {
    sanityDepartment(slug: { current: { eq: $slug } }) {
      title
      slug {
        current
      }
      description
      image {
        asset {
          fluid {
            ...GatsbySanityImageFluid
          }
        }
      }
    }
    products: allSanityProduct(
      filter: { department: { slug: { current: { eq: $slug } } } }
      sort: { fields: title, order: ASC }
    ) {
      edges {
        node {
          title
          slug {
            current
          }
          image {
            asset {
              fluid(maxWidth: 1600) {
                ...GatsbySanityImageFluid
              }
            }
          }
          department {
            title
            slug {
              current
            }
          }
        }
      }
    }
    file01: file(
      relativePath: { eq: "bondmann-servicos-pilares-e-beneficios-01.jpg" }
    ) {
      asset: childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    file02: file(
      relativePath: { eq: "bondmann-servicos-pilares-e-beneficios-02.jpg" }
    ) {
      asset: childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    file03: file(
      relativePath: { eq: "bondmann-servicos-pilares-e-beneficios-03.jpg" }
    ) {
      asset: childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
  }
`
